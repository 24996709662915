<template>
  <div class="user-avatar-upload" >
		<div v-loading="photo_loading" class="user-avatar-container" @click="$refs.profile_image_upload.click()" :style="{ width: size, height: size}">
			<img :src="image_url">
			<div class="avatar-frame">
				<font-awesome-icon style="font-size: 30px; color: rgb(130, 130, 130, 0.6)" :icon="['fas', 'camera']" />
				<input accept="image/*" @change="onImageChange" ref="profile_image_upload" style="display: none" type="file" name="profile-image-upload">
			</div>
		</div>
	</div>
</template>

<script>
import firebase from '@/firebase';

export default {
	props: {
		uploadLocation: {
			type: String,
			required: true
		},
		size: {
			type: String,
			default: '100px'
		}
	},

	data: () => ({
		image_url: '',
		photo_loading: false
	}),

	methods: {
		onImageChange() {
			let file = this.$refs.profile_image_upload.files[0];
			
			if (!file) { return; }

			this.photo_loading = true;

			let uploadTask = firebase
        .storage()
        .ref()
        .child(this.uploadLocation)
				.put(file);
				
			uploadTask.then(snapshot => {
				// Get the url
				snapshot.ref.getDownloadURL().then(async url => {

					try {
						let result = await this.$SharoAPI({ action: 'userAuthHelper', data: { type: 'markUserPhotoAsUploaded', email: '' } });
						if (result.data.error) {
							console.error(result);
						}
					} catch (err) {
						console.error(err);
					}

					this.$store.dispatch('checkUserClaims', true);
					
					this.photo_loading = false;
					this.image_url = url;
					this.$message({ type: 'success', showClose: true, message: 'Photo uploaded successfully' });
					
					this.$emit('onPhotoUploaded', url, this.uploadLocation);

					// Regenerate Short id to clear cache of new photo
					setTimeout(() => {
						this.$store.dispatch('regenerateShortId');
					}, 2000);

					setTimeout(() => {
						this.$store.dispatch('regenerateShortId');
					}, 6000);
				});
			}).catch(err => {
				this.$message({ type: 'error', showClose: true, message: 'Error uploading photo' });
				this.photo_loading = false;
				console.error(err);
			});
		}
	},

	watch: {
		uploadLocation: {
			handler(val) {
				this.image_url = this.getUserPhoto(firebase.auth().currentUser.uid, '128', this.$store.getters.shortId);
			},
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped>
	.user-avatar-upload {
		display: flex;
		justify-content: center;

		.user-avatar-container {
			border: 1px solid rgba(0,0,0,0.1);;
			background-color: rgba(0,0,0,0.05);
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;


			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
				opacity: 0.3;
				border-radius: 50%;
			}

			.avatar-frame {
				position: absolute;
			}

			&:hover {
				background-color: rgba(0,0,0,0.1);
				transition: 0.25s;
				cursor: pointer;
			}
		}

	}
</style>