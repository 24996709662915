import { render, staticRenderFns } from "./UserAvatarUpload.vue?vue&type=template&id=d30c2c56&scoped=true&"
import script from "./UserAvatarUpload.vue?vue&type=script&lang=js&"
export * from "./UserAvatarUpload.vue?vue&type=script&lang=js&"
import style0 from "./UserAvatarUpload.vue?vue&type=style&index=0&id=d30c2c56&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d30c2c56",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\carli\\Documents\\Github\\Sharo Employee New\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d30c2c56')) {
      api.createRecord('d30c2c56', component.options)
    } else {
      api.reload('d30c2c56', component.options)
    }
    module.hot.accept("./UserAvatarUpload.vue?vue&type=template&id=d30c2c56&scoped=true&", function () {
      api.rerender('d30c2c56', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/UserAvatarUpload.vue"
export default component.exports