var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-avatar-upload" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.photo_loading,
            expression: "photo_loading"
          }
        ],
        staticClass: "user-avatar-container",
        style: { width: _vm.size, height: _vm.size },
        on: {
          click: function($event) {
            return _vm.$refs.profile_image_upload.click()
          }
        }
      },
      [
        _c("img", { attrs: { src: _vm.image_url } }),
        _c(
          "div",
          { staticClass: "avatar-frame" },
          [
            _c("font-awesome-icon", {
              staticStyle: {
                "font-size": "30px",
                color: "rgb(130, 130, 130, 0.6)"
              },
              attrs: { icon: ["fas", "camera"] }
            }),
            _c("input", {
              ref: "profile_image_upload",
              staticStyle: { display: "none" },
              attrs: {
                accept: "image/*",
                type: "file",
                name: "profile-image-upload"
              },
              on: { change: _vm.onImageChange }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }